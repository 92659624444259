<template>
	<div style="width:100%;padding: 0;" v-cloak>
		<router-view></router-view>
		<!--
		<tools></tools>
		-->
	</div>
</template>
<script>
//import tools from "../../components/tools";
export default {
	name: 'app',
	beforeMount() {
		if (window.navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)) {
			this.$router.push('/mobile');
		} else {
			this.$router.push('/');
		}
	},
	metaInfo: {
		title: '招投研究院',
		meta: [{
				name: 'keyWords',
				content: '投标,招标,招投标,研究院,招标代理,标书代写,标书审核,招投标资质办理,招投标培训,招投研究院,软件开发'
			},
			{
				name: 'description',
				content: '招投研究院有限公司是一家专注于招标、投标整体能力提升的专业服务机构,为企业提供标书代写、标书制作、招投标培训全方位的招投标服务解决方案,快速提中标率。'
			}
		]
	},
	components:{
		//tools
	},
}
</script>

<style>
	[v-cloak]{
		display: none;
	}
</style>
